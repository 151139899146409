import React from "react";
import Meta from "../../components/Meta";
import ThreeCardRow from "../../components/Custom/ThreeCardRow";
import ResourceCard from "../../components/Custom/ResourceCard";
import TitleCard from "../../components/Custom/TitleCard";
import InfoSection from "../../components/Custom/InfoSection";

var procImage = 'https://firebasestorage.googleapis.com/v0/b/insurace-agency-trainin-9mw02g.appspot.com/o/coverPhotos%2Fproc.png?alt=media&token=06369a59-baf7-4dae-b19e-0eb6c7b5d671'
var tpImage = 'https://firebasestorage.googleapis.com/v0/b/insurace-agency-trainin-9mw02g.appspot.com/o/coverPhotos%2Ftp.png?alt=media&token=ab6b57e5-803d-40d6-88ac-27dd66b92e90'
var sbImage = 'https://firebasestorage.googleapis.com/v0/b/insurace-agency-trainin-9mw02g.appspot.com/o/coverPhotos%2Fsb.png?alt=media&token=8ab5c3b9-bd26-41f8-ad8e-948de1588b5f'
var lifeImage = 'https://firebasestorage.googleapis.com/v0/b/insurace-agency-trainin-9mw02g.appspot.com/o/coverPhotos%2Flife.png?alt=media&token=bc27bf89-d10a-4980-baad-fcd218c595eb'
function CustomIndexPage(props) {
  return (
    <>
      <Meta title = "Landing Page" />
      <div className="px-12">
      <TitleCard title="Our Packages" subtitle="Four unique packages to choose from.">
      <ThreeCardRow>
            <ResourceCard href = {"/packages/sb"} title={"Million Dollar Stairs and Buckets Start-Up"} description={"Stairs & Buckets 101"} photo = {sbImage}/>
            <ResourceCard href = {"/packages/proc"} title={"Million Dollar Tool Kit"} description={"Sales Processes & Webinars"} photo={procImage}/>
            <ResourceCard href = {"/packages/tp"} title={"Million Dollar Road Map"} description={"Training Package & Manuals"} photo={tpImage}/>
            <ResourceCard href={"/packages/life"} title={"All Access Lifetime"} description={"Includes All Current and Future Content"} photo={lifeImage}/>
        </ThreeCardRow>
      </TitleCard>
      </div>
      <InfoSection photo ={sbImage}  subtitle = "Stairs & Buckets 101"header = "Million Dollar Stairs and Buckets Start-Up" href = "/packages/sb" title="Use Stairs and Buckets to sell an additional $100,000 of Life Premium: Try it Risk Free!" perks = {["More Consistent Sales every time!", "A simple 4-minute video tool to educate your team consistently.", "Save time and make $100,000 more using Stairs and Buckets."]}/>

      <InfoSection photo = {tpImage} subtitle = "Training Package & Manuals"  header = "Million Dollar Road Map" href= "/packages/tp" title="Million Dollar Trainings to Take Your Agency to the Next Level" perks = {["These resources address every aspect of agency sales and success."]}/>
      <InfoSection  photo = {procImage} subtitle = "Sales Processes & Webinars"  header = "Million Dollar Tool Kit" href = "packages/proc" title="Perfected Sales Processes To Cover the 7 Risks That All People Face in Life" perks = {["Get access to the best tools for strong results", "Six more sales processes to add to your tool kit."]}/>
      <InfoSection photo = {lifeImage} subtitle = "Includes All Current and Future Content"  header = "All Access Lifetime" href= "/packages/life" title="The Million Dollar Membership: Join the Club" perks = {["Private two day training and networking retreat in Wyoming's majestic mountains.", "One-on-one case by case consulting with million dollar agents.", "Lifetime access to all our resources now and in the future."]}/>
    </>
  );
}

export default CustomIndexPage;
