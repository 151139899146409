import React, { useState } from "react";
import { useAuth } from "../../util/auth";
import { useTeamsByMembership } from "../../util/db";
import { LockClosedIcon } from "@heroicons/react/24/outline";
import PaymentWrapperNode from "./PaymentWrapperNode";
import LoadingIcon from "../LoadingIcon";
import { Link } from "../../util/router";

function haveCommonElements(arr1, arr2) {
   const set1 = new Set(arr1);
   for (const element of arr2) {
     if (set1.has(element)) {
       return true;
     }
   }
   return false;
 }

export default function ValidatePaymentWrapper({children, requiredPlans, small}) {
   const auth = useAuth()
   const [loading, setLoading] = useState(true)
   const [isValid, setIsValid] = useState((auth.user.legacyIsActive && (haveCommonElements(requiredPlans, auth.user.legacyPlans) || auth.user.legacyPlans.includes("life")))||(auth.user.planIsActive && (haveCommonElements(requiredPlans, auth.user.planIds) || auth.user.planIds.includes("life"))))
   const {
      data: teams,
      status: teamsStatus,
      error: teamsError,
   } = useTeamsByMembership(auth.user.uid)

   const handleChildValueChange = (value) => {
      if (value) {
         setIsValid(value)
      }
      setLoading(false)
    };


 return (
    <>
      {(teamsStatus === "success")&&(
         <>
         {(!isValid&&teams.length>0)&&teams.map((team, k) => (team.owner !== auth.user.uid)&&<PaymentWrapperNode requiredPlans={requiredPlans} onValueChange={handleChildValueChange} key = {k} uid = {team.owner} />)}
         
         {(!loading || isValid || teams.filter(team => team.owner !== auth.user.uid).length === 0)?<>
        {(isValid)?children:<div className="relative">
         <div className="pointer-events-none opacity-50">
            {children}
         </div>
         <div className={`absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 text-gray-400 ${small?"w-1/2":"w-1/4"} `}>
            <div className="flex flex-col text-center bg-white border p-4 rounded-lg">
            <LockClosedIcon className="w-full"/> 
            <p className={`text-xl ${small?"hidden":""}`}>This content is protected. You must purchase a plan to access it. Click to visit the <Link to = "/pricing/plans" className="text-green-700">Shop</Link>.</p>
            </div>
         </div>
         </div>
         }
         </>:<LoadingIcon className="w-6"/>}
         </>
      )}
    </>
 )
}

