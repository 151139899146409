import React from "react";
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { useRouter } from "../../util/router";
import { useAuth } from "../../util/auth";
import SideNav from "./SideNav";
import ThreeCardRow from "./ThreeCardRow";
import ToggleButton from "./ToggleButton";
import LinkCard from "./LinkCard";
import AnalyticsCard from "./AnalyticsCard";
import PeopleCard from "./PeopleCard";
import TitleCard from "./TitleCard";
import DownloadButton from "./DownloadButton";
import ResourceCard from "./ResourceCard";
import { useCodesByOwner, useAllVideos, useTeamsByMembership } from "../../util/db";
import {useState, useEffect} from "react";
import PieChartStatsCard from "./PieChartStatsCard";
import PeopleProgressCard from "./PeopleProgressCard";
import ResourceCompletionTable from "./ResourceCompletionTable";
import LiveViewSection from "./LiveViewSection";

function CustomDashboard(props) {
  const auth = useAuth();
  const router = useRouter()
  const [stats, setStats] = useState({})
  const [opened, setOpened] = useState(0)
  const [unOpened, setUnOpened] = useState(0)


  const [oldStats, setOldStats] = useState({})
  const [successfulPayment, setSuccessfulPayment] = useState(router.query.paid)

  
  const [isOpen, setIsOpen] = useState(false);
  const [option, setOption] = useState("Monthly")
  // Function to toggle the dropdown open and close
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const {
    data: teams,
    status: teamssStatus,
    error: teamsError
  } = useTeamsByMembership(auth.user.uid)


  const {
    data: videoCodes,
    status: videoCodesStatus,
    error: videoCodesError
  } = useCodesByOwner(auth.user.uid)

  const {
    data: allVideos,
    status: allVideosStatus,
    error: allVideosError
  } = useAllVideos()

  function calculatePercentage(old, current) {
    var difference = current-old
    var perc = old!==0?(difference/old*100):999
    if (old ===0 && current===0) {
      perc = 0
    }
    return perc
  }

  function concatonateMembers(teams) {
    var array = []

    teams.forEach(team => {
      if (team.members)
        team.members.forEach(memberId => {
          if (!array.includes(memberId)) {
            array.push(memberId)
          }
        })
    })
    return array
  }
  function calculateStats(rawdata) {
    var yeardata = []
    var monthdata = []
    var weekdata = []
    var openedTemp = 0
    var unOpenedTemp = 0
    const currentTime = new Date().getTime();
    rawdata?.forEach(object => {
      if (object.views>0) {
        openedTemp+=1
      } else {
        unOpenedTemp+=1
      }
      if (currentTime - (object.createdAt.seconds*1000) < 365*60*60*24*1000) {
        yeardata.push(object)

        if (currentTime - (object.createdAt.seconds*1000) < 30*60*60*24*1000) {
          monthdata.push(object)
          if (currentTime - (object.createdAt.seconds*1000) < 7*60*60*24*1000) {
            weekdata.push(object)
          }
        }
      }
    })
    var totalviews = calculateTotalViews(rawdata)
    var yearviews = calculateTotalViews(yeardata)
    var monthviews = calculateTotalViews(monthdata)
    var weekviews = calculateTotalViews(weekdata)
    setOpened(openedTemp)
    setUnOpened(unOpenedTemp)
    return {
      shares: {week: weekdata?.length, month: monthdata?.length, year: yeardata?.length, all: rawdata?.length},
      views: {week: weekviews, month: monthviews, year: yearviews, all: totalviews},
      hours: {week: weekviews*(1/12).toFixed(2), month: monthviews*(1/12).toFixed(2), year: yearviews*(1/12).toFixed(2), all: totalviews*(1/12).toFixed(2)},
      sales: {week: 0, month: 0, year: 0, all: 0}
    }
  }
  function calculateOldStats(rawdata) {
    var yeardata = []
    var monthdata = []
    var weekdata = []
    const currentTime = new Date().getTime();
    rawdata?.forEach(object => {

      if (((currentTime - (object.createdAt.seconds*1000)) > 7*60*60*24*1000) && ((currentTime-(object.createdAt.seconds*1000)) < 14*60*60*24*1000)) {
        weekdata.push(object)
        if (((currentTime - (object.createdAt.seconds*1000)) > 30*60*60*24*1000) && ((currentTime-(object.createdAt.seconds*1000)) < 60*60*60*24*1000)) {
          monthdata.push(object)
          if (((currentTime - (object.createdAt.seconds*1000)) > 365*60*60*24*1000) && ((currentTime-(object.createdAt.seconds*1000)) < 730*60*60*24*1000)) {
            yeardata.push(object)
          }
        }
      }
    })
    var totalviews = calculateTotalViews(rawdata)
    var yearviews = calculateTotalViews(yeardata)
    var monthviews = calculateTotalViews(monthdata)
    var weekviews = calculateTotalViews(weekdata)
    return {
      shares: {week: weekdata?.length, month: monthdata?.length, year: yeardata?.length},
      views: {week: weekviews, month: monthviews, year: yearviews},
      hours: {week: weekviews*(1/12).toFixed(2), month: monthviews*(1/12).toFixed(2), year: yearviews*(1/12).toFixed(2)},
      sales: {week: 0, month: 0, year: 0}
    }
  }
  function calculateTotalViews(objects) {
    var sum = 0
    objects?.forEach(object => {
      if (object.views) {
        sum +=object.views
      }
    })
    return sum
  }

  useEffect(() => {
    setStats(calculateStats(videoCodes))
    setOldStats(calculateOldStats(videoCodes))

  }, [videoCodes])


  return (
    <>

<>
      {successfulPayment && (
        <div className="fixed inset-0 z-20 overflow-y-auto">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <svg
                    className="h-6 w-6 text-green-700"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Payment successful</h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Thank you for your purchase. We look forward to working with you!
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-green-600 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                  onClick={() => setSuccessfulPayment(false)}
                >
                  Continue to Dashboard
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>

      <div className="container">
        <LiveViewSection/>
        {(router.query.section === "dashboard")&&(
          <>
            <div className="flex flex-row justify-between items-center pb-4">
          <ToggleButton sectionsArray = {[
          { section: "dashboard", label: "Dashboard" },
          { section: "analytics", label: "Analytics" },
        ]} urlPrefix={"/dashboard"} section = {router.query.section}/>
          </div>
        


        <div className="flex w-full flex-col lg:flex-row">
          <div className="flex-grow-1 w-full lg:w-3/4 px-4">
          <TitleCard title = "All-Time Progress">
            {(videoCodesStatus=== "success" && videoCodes)&&(
            <AnalyticsCard showChange ={false}
            hours = {stats.hours?.all}
            sales = {stats.sales?.all}
            shares = {stats.shares?.all} 
            views = {stats.views?.all}/>
            )}
            <ThreeCardRow></ThreeCardRow>
            </TitleCard>

          </div>
          <div className="flex-grow px-4">
            <LinkCard title = "Team Members" href="/teams/all">
              <PeopleCard uid = {auth.user.uid}/>
            </LinkCard>
          </div>
        </div>
        {(allVideosStatus==="success" && allVideos && allVideos?.length>2)&&(
        <LinkCard title = "Training Resources" subtitle = {`${allVideos.length} Available Resources`} href="/tools/sp">
        <ThreeCardRow>
          {allVideos.map((each, i) => <ResourceCard href = {(i ===0)?'/tools/ip':`/tools/${each.itemId?.includes("shuff")?each.itemId?.slice(0,-1):each.itemId}`} title={each.title} description={each.description} photo = {each.coverPhoto} key={i}/>
          )}
        </ThreeCardRow>
        </LinkCard>
        )}

        </>
        )}
        {(router.query.section === "analytics")&&(
          <div className="container">
            <div className="flex flex-row justify-between items-center">
            <ToggleButton sectionsArray={[
          { section: "dashboard", label: "Dashboard" },
          { section: "analytics", label: "Analytics" },
        ]} urlPrefix={"/dashboard"} section = {router.query.section}/>
              <div className="flex space-x-2">
              <div className="relative inline-block text-left">
                {/* Button to toggle the dropdown */}
                <button onClick={toggleDropdown} className="flex flex-row bg-white text-green-500 py-2 px-4 rounded-md border-2 border-green-200 focus:outline-none focus:border-green-700">
                  {option}
                  <ChevronDownIcon className='h-6 ml-0.5'/>
                </button>

                {/* Dropdown Menu */}
                {isOpen && (
                  <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                      <button className="text-green-500 block px-4 py-2 text-sm w-full text-left hover:bg-green-100" role="menuitem" onClick={() => {toggleDropdown()
                          setOption("Weekly")}}>Weekly</button>
                      <button className="text-green-500 block px-4 py-2 text-sm w-full text-left hover:bg-green-100" role="menuitem" onClick={() => {toggleDropdown()
                          setOption("Monthly")
                      }}>Monthly</button>
                      <button className="text-green-500 block px-4 py-2 text-sm w-full text-left hover:bg-green-100" role="menuitem" onClick={() => {toggleDropdown()
                          setOption("Yearly")
                      }}>Yearly</button>
                    </div>
                  </div>
                )}
              </div>
              <div className="lg:block hidden">
              <DownloadButton />
              </div>
              </div>
            </div>
            <div className="flex w-full lg:flex-row lg:space-x-4 flex-col">
            <div className="flex-grow-1 lg:w-3/4 w-full"> 
              <TitleCard title = "Individual Access Codes Sent">
                <PieChartStatsCard opened={opened} unopened={unOpened}/>
              </TitleCard>
              {(videoCodesStatus=== "success" && videoCodes)&&(
              <AnalyticsCard
                showChange={true}
               sales = {(option === "Weekly")?stats.sales.week:(option === "Monthly")?stats.sales.month:stats.sales.year}
               salePerc = {(option === "Weekly")?calculatePercentage(oldStats.sales.week, stats.sales.week):(option === "Monthly")?calculatePercentage(oldStats.sales.month, stats.sales.month):calculatePercentage(oldStats.sales.year, stats.sales.year)}
               hours = {(option === "Weekly")?stats.hours.week:(option === "Monthly")?stats.hours.month:stats.hours.year}
               hourPerc = {(option === "Weekly")?calculatePercentage(oldStats.hours.week, stats.hours.week):(option === "Monthly")?calculatePercentage(oldStats.hours.month, stats.hours.month):calculatePercentage(oldStats.hours.year, stats.hours.year)}
               shares = {(option === "Weekly")?stats.shares.week:(option === "Monthly")?stats.shares.month:stats.shares.year}
               sharePerc = {(option === "Weekly")?calculatePercentage(oldStats.shares.week, stats.shares.week):(option === "Monthly")?calculatePercentage(oldStats.shares.month, stats.shares.month):calculatePercentage(oldStats.shares.year, stats.shares.year)}
               viewPerc = {(option === "Weekly")?calculatePercentage(oldStats.views.week, stats.views.week):(option === "Monthly")?calculatePercentage(oldStats.views.month, stats.views.month):calculatePercentage(oldStats.views.year, stats.views.year)}
               views = {(option === "Weekly")?stats.views.week:(option === "Monthly")?stats.views.month:stats.views.year}/>
              )}
            </div>
            <div className="flex-grow">
              <TitleCard title = "Team Webinar Completion" subtitle={`Team Member Progress`} >
                <div className="border border-green-700 rounded-lg">
                {allVideos && allVideosStatus === "success"&& teams && teamssStatus==="success" && (
                  <ResourceCompletionTable ids = {concatonateMembers(teams)} videos = {allVideos}/>
                )}

                </div>
              </TitleCard>
            </div>
          </div>
          <TitleCard title = "Team Utilization Analytics" subtitle = "Team Member Statistics">
            {teamssStatus === "success" && teams&&
        <PeopleProgressCard teams = {teams}/>}
        </TitleCard>
          </div>
        )}

      </div>
      <SideNav current = "dashboard"></SideNav>

    </>

  );
}

export default CustomDashboard;
