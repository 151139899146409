import React from "react";
import { requireAuth, useAuth } from "../../util/auth";
import CRMSection from "../../components/Custom/CRMSection";
import { useUserOnce } from "../../util/db";
import HostZoom from "../../components/Custom/HostZoom";
import LargeCard from "../../components/Custom/LargeCard";
import PostWebinar from "../../components/Custom/PostWebinar";

function CRMPage(props) {
    const auth = useAuth()
    const {
        data: self,
        status: selfStatus,
        error: selfError
    } = useUserOnce(auth.user.uid)


    return (
    <>
        {(selfStatus === "success" && self.isAdmin === true)?(
            <>
            <div className="container">
            <LargeCard titleDescriptions = {[{title: "Go Live", description: "You can set a notification to users who would like to view this livestream. Please host the meeting on zoom and paste the link."}]}>
                <HostZoom/>
            </LargeCard>
            </div>
            <div className="container">
            <LargeCard titleDescriptions = {[{title: "Post a Webinar", description: "Here you can post a webinar that was previously done."}]}>
                <PostWebinar/>
            </LargeCard>
            </div>

            <CRMSection/>
            </>
        ):(
            <p>Please wait. We are verifying your access to this page.</p>
        )}
    </>
  );
}

export default requireAuth(CRMPage);
